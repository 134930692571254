* {
   scrollbar-width: thin;
}

a {
   text-decoration: none !important;
}

.hide {
   display: none !important;
}

.show {
   display: block !important;
}

.dropzone {
   flex: 1;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 20px;
   border-width: 2px;
   border-radius: 2px;
   border-color: #eeeeee;
   border-style: dashed;
   background-color: #fafafa;
   color: #bdbdbd;
   outline: none;
   transition: border 0.24s ease-in-out;
}

.dropzone_thum_img {
   display: inline-block;
   border: 1px solid #ccc;
   /* Add border for the container */
   padding: 5px;
   /* Add padding around the container */
   margin: 5px;
   /* Add space between containers */
}

.dropzone_thumb {
   display: flex;
   align-items: center;
   /* Center items vertically */
}

.dropzone_img {
   width: 100px;
   height: auto;
   margin-right: 10px;
   /* Add space between image and radio button */
   margin-left: 20px;
}

.thumbcontainer {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
}

.react-dropdown-select-dropdown {
   width: 100% !important;
}

.react-dropdown-select-input {
   font-size: medium;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
   margin-bottom: 0px !important;
}

.error {
   color: red;
}

.list {
   user-select: none;
   display: grid;
   grid-template-columns: auto auto auto auto;
   grid-gap: 40px;
}

.item {
   display: block;
   justify-content: center;
   align-items: center;
   text-align: center;
   color: Black;
   padding-top: 30px;
   height: 150px;
   width: 200px;
   cursor: grab;
   font-size: 20px;
   user-select: none;
   border: 1px solid #adadad;
}

.item img {
   width: auto;
   height: 70px;
   cursor: grab;
}

.dragged {
   background-color: rgb(37, 37, 197);
}

@media (min-width: 600px) {
   .list {
      grid-gap: 50px;
   }
}

.card-border {
   box-shadow: 0 1px 2px 0 rgb(0 0 0 / 88%) !important;
}

.antformrange {
   display: flex !important;
}

.close_img {
   line-height: 12px;
   width: 18px;
   font-size: 15px;
   font-family: tahoma;
   margin-top: 1px;
   margin-right: 2px;
   position: absolute;
   cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
input[type="number"] {
   -moz-appearance: textfield;
   appearance: textfield;
}

.ant-tabs-dropdown {
   z-index: 999999;
}

.list .item p {
   font-size: 14px;
}

.dragBtn {
   width: 40px;
   height: 40px;
   text-align: center;
   line-height: 40px;
}

.swipeable-list-item__content {
   justify-content: space-between !important;
}

.borderBtn {
   border-width: 2px !important;
   border-color: #8b8b8b !important;
   height: 50px;
   padding: 4px;
}

.ReactCrop__child-wrapper {
   display: block;
   position: absolute;
}

.css-1cbdnml-InputComponent {
   line-height: inherit;
   border: none;
   margin-left: 5px;
   background: transparent;
   padding: 0;
   width: calc(13ch + 5px);
   /* font-size: smller; */
}

/* CustomInput.css */
.custom-input-container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   width: 475px;
   /* Set input box width */
}

.tag-container {
   display: flex;
   overflow-x: auto;
   /* Enable horizontal scrolling */
   width: 100%;
   padding: 8px 0;
   /* Adjust padding as needed */
}

.tag-container .ant-tag {
   margin-right: 8px;
   /* Adjust margin between tags */
}

.welcome-page {
   margin-top: 15%;
   text-align: center;
}

.welcome-page .welcome-content h2 {
   margin-top: 25px;
   font-size: 45px;
   color: #6e6e6e !important;
}

.welcome-page .welcome-content strong {
   margin-top: 25px;
   font-size: 45px;
   color: var(--primary-color) !important;
}

/* Antd Table Row background Color (Common for all Antd Tables) */
.ant-table-tbody tr:nth-child(even) {
   /* background-color: #f4f7f6; */
   background-color: #f6fbfd;
}

.ant-table-tbody tr .ant-table-column-sort {
   background: transparent;
}

.primary-btn {
   background-color: var(--primary-color) !important;
   color: white !important;
   border: none;
}

.secondary-btn {
   background-color: grey;
   color: white;
}
